@import '../../assets/css/colors.scss';
.login-form{
   
    min-height:calc(100vh - 182px);  ;
}
.password-field {
    display: flex;
    flex-direction: column;
 
  }
  
  .password-input-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .password-input-container input {
    flex-grow: 1;
  }
  
  .password-input-container .password-icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .info{
    color:#003363 !important;
  }
  .cond-gen{
    color:#336696 !important;
      a
      { 
      font-family: "Outfit-Regular";
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 15px !important;
      letter-spacing: 0em;
      text-align: left;
    }
   
  }
  #links-style{
      font-size: 15px !important;
      text-decoration: solid!important;
  }
  .sso{
    .box{
    padding: 18px 42px 18px 24px;
    border-radius: 32px;
    border: 2px solid $primary-color;
    }
    .google{
      background-image: url('../../assets/imgs/google.svg');
      background-repeat: no-repeat;
      background-position: 10px;
    }
    .facebook{
      
        background-image: url('../../assets/imgs/fcb.svg');
        background-repeat: no-repeat;
        background-position: 10px;
      
    }
  }
  .form-switch .form-check-input{
    --bs-form-switch-bg: url('../../assets/imgs/toggle.svg') !important;
    width: 52px !important;
    height: 26px !important;
    margin-left: -2.5em;
    background-color: #E0E0E0 !important;
    background-position: left center;
    border-radius: 16px;
    box-shadow: none !important;
    transition: background-position 0.15s ease-in-out;
  }
  .form-switch .form-check-input:checked{
    background-color: $primary-color !important;
    box-shadow: none !important;
  }
  .custom-switch-btn{
    //styleName: Button/S;
font-family: "Outfit-SemiBold";
font-size: 16px !important;
font-weight: 500 !important;
line-height: 20px;
letter-spacing: 0em;

  }
  .a-propos{
    background-image: url('../../assets/imgs/Plombier.png');
    background-repeat: no-repeat;
    background-position: bottom left;
  }
  .reset-form{
    background-color: #fff;
  }
  .code-input{
    border-radius: 8px !important;
  }
  // input[type="checkbox"] {
  //   --s: 50px; /* adjust this to control the size*/
    
  //   height: calc(var(--s) + var(--s)/5);
  //   width: auto; /* some browsers need this */
  //   aspect-ratio: 2.25;
  //   border-radius: var(--s);
  //   margin: calc(var(--s)/2);
  //   display: grid;
  //   cursor: pointer;
  //   background-color:#E0E0E0;
  //   box-sizing: content-box;
  //   overflow: hidden;
  //   transition: .3s .1s;
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   appearance: none;
  // }
  // input[type="checkbox"]:before{
  //   content: "";
  //   padding: calc(var(--s)/10);
  //   --_g: radial-gradient(circle closest-side at calc(100% - var(--s)/2) 50%,#000 96%,#0000);
  //   background: 
  //     var(--_g) 0 /var(--_p,var(--s)) 100% no-repeat content-box,
  //     var(--_g) var(--_p,0)/var(--s)  100% no-repeat content-box,
  //     #fff;
  //   mix-blend-mode: darken;
  //   filter: blur(calc(var(--s)/12)) contrast(11);
  //   transition: .4s, background-position .4s .1s,
  //     padding cubic-bezier(0,calc(var(--_i,-1)*200),1,calc(var(--_i,-1)*200)) .25s .1s;
  // }
  // input[type="checkbox"]:checked {
  //   background-color: #0044AB;
  // }
  // input [type="checkbox"]:checked:before {
  //   padding: calc(var(--s)/10 + .05px) calc(var(--s)/10);
  //   --_p: 100%;
  //   --_i: 1;
  // }
  