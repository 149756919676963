@import '../../assets/css/colors.scss';
.img-product{
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    min-height:300px;
   
}
.img-toggle{
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
 
}
.white-bg{
    position: relative;
} 
  .img-product img {
    width: 100%; /* Ensure the image fills its container */
    
  }
  
  .like-share-buttons {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    display: flex;
    gap: 10px; /* Adjust as needed */
  }
  
  .like-share-buttons img {
    width: 30px; /* Adjust as needed */
    height: 30px; /* Adjust as needed */
    cursor: pointer; /* Add cursor pointer to indicate it's clickable */
  }
  .details-btn{
    background-color: rgba(204, 218, 238, 0.5) !important;
    border-radius: 50%;
    padding: 8px;
  }
  .add-to-cart{
    border: 1px solid rgba(204, 218, 238, 1);
    padding: 24px;
    border-radius: 8px;

  }
  .qte-container{
    border: 2px solid rgba(0, 68, 171, 1);
    border-radius: 32px;
    padding: 14px 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .qte-btn{
        border-radius: 50%;
        width: 32px;
        height: 32px;
        border: 2px solid rgba(0, 68, 171, 1);
        color:rgba(0, 68, 171, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent !important;
    }
    .qte-btn:disabled{
        border: 2px solid rgba(153, 179, 203, 1) !important;
        color:#99b3cb  !important;
    }
  }
  .doc{
    font-family: "Outfit-SemiBold";
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 26, 50, 1);
    text-align: left;


  }
  .grey-row{
    background-color: rgba(247, 249, 252, 1);
  }
  .white-row{
    background-color: #fff;
  }
  .p-16-8{
    padding: 16px 8px;
  }
  .value-opt{
    color: rgba(0, 26, 50, 1) !important;
    //styleName: Body/S/Exergue;
    font-family: "Outfit-Bold";
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

  }
  .key-opt{
    font-family: "Outfit-Medium";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  }
  .mt-8{
    margin-top: 8px ;
  }
  .tech-title{
    font-family: "Outfit-SemiBold";
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 26, 50, 1) !important;

  }
  .tech-doc{
    border: 1px solid rgba(204, 217, 229, 1);
padding: 24px 16px;
border-radius: 8px;
 }
 .title-category{
overflow: hidden;
display:  -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
 }
 .product-title{
  //styleName: Body/S/Exergue;
font-family: "Outfit-Regular";
font-size: 16px !important;
font-weight: 600 !important;
line-height: 20px !important;
letter-spacing: 0em;
text-align: left;
color: $bleu800 !important;
 }
 /* Hide scrollbar but allow scrolling */
.offcanvas-body {
  overflow-y: scroll; /* Enable vertical scrolling */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE/Edge */
}

.offcanvas-body::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for Chrome, Safari, and Opera */
}

/* Style the scrollbar track */
.offcanvas-body::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Light grey */
}

/* Style the scrollbar thumb */
.offcanvas-body::-webkit-scrollbar-thumb {
  background-color: #888; /* Grey */
  border-radius: 10px; /* Roundness of the thumb */
}

/* Style the scrollbar on hover */
.offcanvas-body::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Dark grey */
}
.fixed-price-block {
  position: fixed;
  top: 50px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  background-color: rgba(247, 249, 252, 1);
  z-index: 1000; /* Ensure it's above other content */
}
.fixed-block-price{
  position:fixed;
  right:75px;
}
@media screen and (max-width:768px){
 
  .fixed-block-price{
    position:inherit !important;
    right: inherit !important;
  }
}
