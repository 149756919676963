@import './colors.scss';
.card-marion{
    padding: 24px !important;
    border-radius: 16px !important;
    border: 1px solid rgba(204, 218, 238, 1) !important;
    gap: 16px;
    min-height: 315px;

}
.card-marion-product{
    padding: 24px !important;
    border-radius: 16px !important;
    border: 1px solid rgba(242, 245, 248, 1) !important;
    gap: 16px;
    min-height: 500px;
}
.card-adr{
    padding: 24px;
    border-radius: 16px;
    border: 1px solid #F2F5F8 !important;
    gap: 16px;
    min-height: 150px;

}
.card-payment{
    border: 1px solid #F2F5F8 !important;
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
}
.card-grey{
    background: #F7F9FC;
    padding: 15px;
}
.product-image-container {
    height: 200px; /* Adjust this value as needed */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.title-product{
    color: #001a32 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    min-height: 90px;
}
.product-marque{
    //styleName: Body/S;
font-family: "Outfit-Regular";
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
margin: 0 !important;

}
.see-details{
    //styleName: Button/m;
font-family: "Outfit-Medium";
font-size: 18px !important;
font-weight: 500 !important;
line-height: 23px !important;
letter-spacing: 0em;
text-align: left;
color: rgba(0, 68, 171, 1) !important;

}
.offcanvas.offcanvas-end {
    width: 500px !important;
    padding: 32px;
}
.border-top-card{
    border-top: 1px solid rgba(247, 249, 252, 1) !important;
    padding-top: 24px;
    margin-bottom: 16px;
}
.adr-name{
    //styleName: Body/S/Exergue;
font-family: "Outfit-Bold";
font-size: 16px !important;
font-weight: 600 !important;
line-height: 20px !important;
letter-spacing: 0em;

color:$bleu700;
}
@media screen and (min-width:768px ) and (max-width:1200px) {
    .card-marion{
        // img{
        //     width: 100%;
        // }
    }
}