@import './colors.scss';

@font-face {
    font-family: "Outfit-Medium";
    src: url("../fonts/Outfit-Medium.ttf");
  }
  @font-face {
    font-family: "Outfit-ExtraBold";
    src: url("../fonts/Outfit-ExtraBold.ttf");
  }
  @font-face {
    font-family: "Outfit-Bold";
    src: url("../fonts/Outfit-Bold.ttf");
  }
  @font-face {
    font-family: "Outfit-SemiBold";
    src: url("../fonts/Outfit-SemiBold.ttf");
  }
  @font-face {
    font-family: "Outfit-Regular";
    src: url("../fonts/Outfit-Regular.ttf");
  }
h1{
    font-family: "Outfit-ExtraBold";
    font-size: 72px !important;
    font-weight: 800 !important;
    line-height: 69px !important;
    letter-spacing: 0em !important;
    text-align: left !important;  
    color: $bleu500 !important; 
}
h2{
    font-family: "Outfit-ExtraBold";
    font-size: 56px !important;
    font-weight: 800 !important;
    line-height: 71px !important;
    letter-spacing: 0em;
    text-align: left !important;
    color: $primary-color !important; 


}
h3{
    font-family:  "Outfit-ExtraBold";
    font-size: 48px !important;
    font-weight: 800;
    line-height: 60px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: $primary-color !important; 

    
}
h4{
    font-family: "Outfit-Bold";
    font-size: 40px !important;
    font-weight: 700 !important;
    line-height: 50px !important;
    letter-spacing: 0em !important;
    text-align: left;
    color: $primary-color !important; 

    
}
h5{
    font-family:"Outfit-Bold";
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    letter-spacing: 0em;
    text-align: left;
    color: $bleu800 !important; 

    
}
h6{
    font-family:"Outfit-Bold";
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    letter-spacing: 0em;
    text-align: left;
    color: $bleu800 !important; 

    
}
label{
    font-family: "Outfit-Regular";
    font-size: 16px !important;
    font-weight: 400 !important;
    //line-height: 15px !important;
    letter-spacing: 0em;
    text-align: left !important;
    color: rgba(153, 179, 203, 1) !important;

}
p{
  //styleName: Body/S;
font-family: "Outfit-Regular";
font-size: 16px !important;
font-weight: 400 !important;
line-height: 20px !important;
letter-spacing: 0em;
text-align: left;
}
.body-s{
  //styleName: Body/S;
font-family: "Outfit-Regular";
font-size: 16px !important;
font-weight: 400 !important;
line-height: 20px !important;
letter-spacing: 0em;
text-align: left;
color:$bleu800 ;
}
.bleu600-body-s{
  //styleName: Body/S;
font-family: "Outfit-Regular";
font-size: 16px !important;
font-weight: 400 !important;
line-height: 20px !important;
letter-spacing: 0em;
text-align: left;
color:$bleu600 !important;
}
.bleu700-body-s{
  //styleName: Body/S;
font-family: "Outfit-Regular";
font-size: 16px !important;
font-weight: 400 !important;
line-height: 20px !important;
letter-spacing: 0em;
text-align: left;
color:$bleu700 !important;
}
.bleu800-body-s{
  //styleName: Body/S;
font-family: "Outfit-Regular";
font-size: 16px !important;
font-weight: 400 !important;
line-height: 20px !important;
letter-spacing: 0em;
text-align: left;
color:$bleu800 !important;
}
.legend{
  //styleName: Légende;
font-family: "Outfit-Regular";
font-size: 14px !important;
font-weight: 400 !important;
line-height: 18px !important;
letter-spacing: 0em;
color: rgba(51, 102, 150, 1);
}
.body-m{

font-family: "Outfit-Medium" !important;
font-size: 18px !important;
font-weight: 500 !important;
line-height: 23px !important;
letter-spacing: 0em;
text-align: left;
color: #00407C;

}
.subtitle-s{
  font-family: 'Outfit-Medium' !important;
font-size: 18px !important;
font-weight: 500 !important;
line-height: 23px !important;
letter-spacing: 0em;
text-align: left;

}
.subtitle-m{
  font-family: 'Outfit-Medium' !important;
font-size: 16px !important;
font-weight: 500 !important;
line-height: 25px !important;
letter-spacing: 0em !important;
text-align: left;
color:$bleu600;
}
.bleu600{
  color:$bleu600 !important;
}
.bleu700{
  color:$bleu700 !important;
}
.bleu500{
  color:$bleu500 !important;
}
.bleu400{
  color:$bleu400 !important;
}
.bleu800{
  color:$bleu800 !important;
}
.min-vh-60{
  min-height: 60vh !important;
}
.grey-hr{
  border-right: 1px solid rgba(204, 217, 229, 1) !important;
}
.search-adr{
  background: rgba(247, 249, 252, 1);
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.adresse-marion{
  background-image:url('../imgs/fi_map-pin.svg'),url('../imgs/fi_search.svg');
  background-repeat: no-repeat;
  background-position: 4%,  96%;
  padding-left: 50px !important;
  padding-right: 16px !important;
  border: 1px solid rgba(204, 217, 229, 1) !important;


}
a{
  //styleName: Button/m;
font-family: "Outfit-Medium";
font-size: 18px !important;
font-weight: 500 !important;
line-height: 23px !important;
letter-spacing: 0em !important;
text-align: center;
color:$primary-color !important ;
text-decoration: none !important;

}
.link-s{
  //styleName: Button/m;
font-family: "Outfit-Medium";
font-size: 16px !important;
font-weight: 500 !important;
line-height: 20px !important;
letter-spacing: 0em !important;
text-align: center;
background-color: transparent !important;
border: none !important;
color:$primary-color !important ;
text-decoration: none !important;
cursor: pointer;

}
a:hover{

color:$primary-color-hover !important;
}
select{
  border: 1px solid rgba(204, 218, 238, 1) !important;
  border-radius: 8px !important;
  padding: 8px !important;
}
header input[type="search"]{
  padding: 16px 29px 16px 16px !important;
}
.input-bg-white{
  background-color: #fff !important;
}
input[type="search"]{
  padding: 8px 8px 8px 16px;
  border-radius: 24px;
  gap: 10px;
  background-color: rgba(247, 249, 252, 1);
  border:none !important;
  background-image: url('../imgs/searchIcon.svg');
  background-repeat: no-repeat;
  background-position: 97%;
  padding-right: 40px;
  color:$bleu700 !important;
}
input[type="search"]:focus{
  box-shadow: none !important;
  border: 2px solid rgba(0, 68, 171, 1) !important;
}
input[type="text"],input[type="email"],input[type="password"],input[type="tel"],input[type="number"],input[type="date"],
.custom-input input{
  padding: 16px;
  border-radius: 24px;
  border: 1px solid rgba(204, 217, 229, 1);
  gap: 8px;
  width: 100%;
  color:$bleu700 !important;
}
.custom-input input,.CardField-number{
  padding: 16px !important;
  border-radius: 24px !important;
  border: 1px solid rgba(204, 217, 229, 1) !important;
  gap: 8px;
  width: 100%;
  color:$bleu700 !important;
}
input[type="text"]:focus,input[type="email"]:focus,input[type="password"]:focus,input[type="tel"]:focus,input[type="number"]:focus{
  padding: 16px;
  border-radius: 24px;
  border: 2px solid rgba(0, 68, 171, 1) !important;
  gap: 8px;
  box-shadow: none !important;
  color:$bleu700 !important;
  
}
::placeholder{
  //styleName: Body/S;
  font-family: "Outfit-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(102, 140, 176, 1) !important;
}
.f-16{
  //styleName: Body/S/Exergue;
font-family: "Outfit-Bold";
font-size: 16px !important;
font-weight: 600 !important;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
}
.Exergue{
  font-family: "Outfit-Bold";
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px;
}
.border-top-marion{
  border-top: 1px solid #F2F5F8 !important;
}
.f72{
  font-size: 72px !important;
  font-weight: 800 !important;
}
.mr-5{
  margin-right:5px !important;
}
.mr-8{
  margin-right:8px !important;
}
.mr-12{
  margin-right:12px !important;
}
.mr-16{
  margin-right:16px;

}
.mr-24{
  margin-right:24px !important;
}
.mr-40{
  margin-right:40px !important;
}
.mt-24{
  margin-top: 24px !important;
}
.mt-32{
  margin-top: 32px !important;
}
.mt-16{
  margin-top:16px !important;
}
.mt-14{
  margin-top:14px !important;
}
.mt-20{
  margin-top: 20px !important;
}
.mt-40{
  margin-top: 40px !important;
}
.mb-24{
  margin-bottom: 24px !important;
}
.mb-60{
  padding-bottom: 60px !important;
}
.ml-8{
  margin-left: 8px !important;
}
.pb-16{
  padding-bottom: 16px;
}
.pl-16{
  padding-left: 16px;
}
.pb-32{
  padding-bottom: 32px !important;
}
.mt-8{
  margin-top: 8px !important;
}
.mt-18{
  margin-top: 18px;
}
.mt-40{
  margin-top: 40px !important;
}
.my-32{
    padding-top: 32px;
    padding-bottom: 32px;
}
.pt-24{
  padding-top: 24px !important;
}
.pt-32{
  padding-top: 32px !important;
}
.pt-60{
  padding-top: 60px !important;
}
.pt-70{
  padding-top: 70px !important;
}
.p-16{
  padding: 16px !important;
}
.pr-8{
  padding-right: 8px !important;
}
.pt-16{
  padding-top: 16px !important;
}
.pt-56{
  padding-top: 56px !important;
}
.p-24{
  padding: 24px !important;
}
.bg-auth{
  background-color: rgba(247, 249, 252, 1) !important;
}
.white-bg{
  background-color: white;
}
.container-brands
{
  padding: 56px 76px;
}

.bleu800{
  color:$bleu800 !important;
}
.link-cursor{
  cursor: pointer;
}

.openFilter img{
  transform: rotate(90deg);
}
.fit-content{
  width:fit-content !important;
}
// pagination
.link-pag{
  background-color: rgba(204, 218, 238, 1) !important;
  border-radius: 50% !important;
  // display: flex !important;
  // align-items: center !important;
  padding: 12px !important;
}
//AUto complete style
.mapItem{
  box-sizing: border-box;
  padding: 10px 10px 10px 30px;
  font-size:15px;
  border-radius: 30px;
}
.mapItem:hover{
background-color: $bleu500 !important;
color: #FFF;
cursor:pointer
}
.container-autocomplete{
  padding: 10px;
  border-radius: 16px !important;
  border: 1px solid #e6e6e6 !important;
  position: absolute;
  z-index: 999999;
  background-color: #fff;
  width: 100%;
}
.badge-ongoing{
    top: 1868px;
    left: 123px;
    padding: 8px 12px 8px 12px;
    gap: 8px;
    border-radius: 24px;
    opacity: 0px;
    background-color: #FFF4F3;
    color: #FF7971;
}
.no-border{
  border: none !important;
}
@media screen and (max-width:768px) {
  .h-mobile{
  display: none;
  }
  .mt-sm-12{
    margin-top:12px !important;
  }
}
@media screen and (max-width:425px){
  .mt-sm-12{
    margin-top:12px !important;
  }
}
.margin-32{
  margin-bottom: 32px !important;
}
.margin-16{
  margin-bottom: 16px !important;
}