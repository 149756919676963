footer{
    padding: 32px 76px;
}
@media screen and (max-width:426px) {
    .social{
        .mr-24{
            margin-right:8px !important;

        }
    }
    footer{
        padding: 32px !important;
    }
}