header{
   padding: 32px 76px;
   background-color: #fff;
   border-bottom: 1px solid rgba(242, 245, 248, 1);
//    display: flex;
//    flex-direction: row;
}
.sub-cat{
  //styleName: Body/S;
font-family: "Outfit-Regular";
font-size: 16px !important;
font-weight: 400 !important;
line-height: 20px !important;
letter-spacing: 0em !important;
text-align: center;


}
.adr{
    padding: 15px 12px ;
    border-radius: 24px !important;
    gap: 8px;
    background-color: rgba(247, 249, 252, 1) !important;
    color:rgba(0, 38, 74, 1) !important;
    border: none !important;
  
}
.adr-hover:hover{
  background-color: #CCDAEE !important;
}
.adr-hover:focus{
  background-color: #99B4DD !important;
}
.hover-link{
  padding: 8px;
}
.hover-link:hover{
  background-color: #F7F9FC !important;
  border-radius: 50%;
}
.hover-link:focus{
  background-color: #CCDAEE !important;
  border-radius: 50%;
}
.adr-item{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
}
.adr-text{
    overflow: hidden; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; white-space: nowrap;
}
.search-res{
  //styleName: Body/S;
font-family: 'Outfit-Regular' !important;
font-size: 16px !important;
font-weight: 400 !important;
line-height: 20px !important;
letter-spacing: 0em !important;
text-align: left !important;
color: rgba(0, 38, 74, 1) !important;



}
.sub-menu{
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
    border-radius: 16px 0px 0px 16px;
    box-shadow: 0px 4px 19px 0px rgba(86, 86, 86, 0.06);
    gap: 24px;
    border-radius: 16px ;
    position: absolute;
    left: 100px;
    background-color: #fff;
    // margin-top: 2px;
    width:70%;
    z-index:999;
    
    .bg-gris{
        background-color:rgba(242, 245, 248, 1) ;
    
        padding: 32px;
        border-radius: 16px 0px 0px 16px !important;
    }
    .right{
        padding: 32px;
        
    }

}
.active-menu{
   
    border-radius: 32px;
    background-color: #CCDAEE !important;
    
}
.metiers{
    padding: 8px 16px 8px 16px;
    margin: 0px !important;
}
.metiers:hover{
    background-color: #F7F9FC !important;
    border-radius: 32px;
}
.metiers:focus{
  background-color: #CCDAEE !important;
  border-radius: 32px;
}
.pt-color{
    color:rgba(255, 31, 18, 1) !important;
}
.sub-menu.open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease, visibility 0s linear 0s;
  }
  .toggle-menu{
    background-color: transparent !important;
    border: none !important;
  }
  .dropdown-menu.show{
    padding: 24px;
    margin-top: 8px !important;
  }
  .drop-header{
    border: none !important;
    padding: 24px;
    border-radius: 16px;
    gap: 16px;
    box-shadow: 0px -4px 19px 0px rgba(86, 86, 86, 0.06);
    width: 290px;

  }
  .search-terms{
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
    border-radius: 16px 0px 0px 16px;
    box-shadow: 0px 4px 19px 0px rgba(86, 86, 86, 0.06);
    gap: 24px;
    border-radius: 16px ;
    margin-top: 2px;
    padding: 24px 32px;
    width: 100%;
  }
  .relative{
    position: relative !important;
  }
  .mobile{
    display:none;
  }
  .desktop{
    display:block
  }

@media screen and (max-width:768px) {
  .mobile{
    display:block;
  }
  .desktop{
    display:none
  }
  header{
    padding: 12px;
  }
  .sub-menu{
    width:100% !important;
    left: 0 !important;
  }}
  @media screen and (max-width: 768px) {
    /* Adjust the placeholder text for smaller screens */
    .adr::placeholder {
      /* Display only a few words with ellipsis (...) */
      content: "Rechercher...";
    }
  }
  @media screen and (min-width:992px) and (max-width:1105px) {
    header{
      padding: 32px 20px;
    }
  }