@import '../../assets/css/colors.scss';
.sidebar-profil{
    .sub-sidebar{
     padding: 24px 24px 24px 24px;   
    }
   
    .active-link {
        font-size: 18px;
        font-family: "Barlow";
        font-weight: 600;
        color: rgba(18, 16, 16, 1); 
    }
    .sub-sidebar{
         background-color:#fff!important ;
        //background-color: red;
        min-height: 80vh;
        
    }
    .list-item{
        padding:24px;
        border-bottom: 1px solid rgba(242, 245, 248, 1);
        font-family: "Outfit-Regular";
        font-size: 18px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        list-style: none;
        cursor: pointer;
    }
    .bottom-link {
        position: absolute;
        bottom: 0;
        color:$primary-color;
        border-bottom: none !important;
        font-size: 16px;
        font-family: "Barlow";
        font-weight: 600;
        text-decoration: underline;
        span{
            font-size: 16px !important;
            font-family: "Barlow";
            font-weight: 600 !important;
            color: rgba(251, 38, 59, 1) !important;
            width: 92px;
            height: 19px;
        }
    }
   
}
.order-filtre {
    .select-filtre{
        background-color: #fff !important;
    }
}
.react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after
{
    font-family: 'Outfit-Regular';
}
span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from{
    text-transform: capitalize;
    font-size: 24px;
    font-family: 'Outfit-Bold';
    font-size: 24px;
    font-weight: 700;
    line-height: 30.24px;
    text-align: left;

}
.react-calendar{
    border: none !important;
    abbr{
        text-decoration: none !important;
    }
}
.react-calendar__month-view__days__day--neighboringMonth{
    color:#99B3CB !important;
}
.react-calendar__tile--now{
    background-color: transparent !important;
}
.react-calendar__month-view__days__day--weekend{
    color: $bleu800  ;
    //styleName: Body/S;
    font-family: 'Outfit-Regular' !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.react-calendar__tile{
    color: $bleu800 ;
    //styleName: Body/S;
    font-family: 'Outfit-Regular' !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}
.react-calendar__tile--active{
    border-radius: 50% !important;
    background-color: #0044AB !important;
}
.react-calendar__navigation button:enabled:hover{
    background-color: transparent !important;
}
.dropdown-item:hover, .dropdown-item:focus{
    background-color: transparent ;
}
@media screen and ( max-width:873px) {
    .sidebar-profil .sub-sidebar{
        min-height: auto !important;
    }
    .sidebar-profil .bottom-link{
        position: inherit;
    }
    .w-sm-100{
        width: 100% !important;
    }
    
}
@media screen and (max-width:425px) {
    .sidebar-profil .sub-sidebar {
        padding: 0;
    }
    .sidebar-profil .list-item {
    padding: 18;
    }
    
}