.white-bg-card{
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(242, 245, 248, 1);
    padding: 16px;
    border-radius: 8px;  
}
.nb-article{
    //styleName: Body/M;
font-family: "Outfit-Medium";
font-size: 18px;
font-weight: 500;
line-height: 23px;
letter-spacing: 0em;
text-align: left;
color: rgba(0, 38, 74, 1) !important;

}
.prog-circul{
    //styleName: Heading/6;
font-family: "Outfit-Bold";
font-size: 24px !important; 
font-weight: 700 !important;
line-height: 30px !important;
letter-spacing: 0em;
text-align: center !important;
// padding-right: 16px !important;

}
.grey-border{
    border: 1px solid rgba(204, 217, 229, 1);
    padding: 16px;
    border-radius: 8px;
}
.success-text{
   color:#6ECD33 !important; 
}
.danger-text{
    color:#FF1F12 !important;
}
