 /* DynamicTable */
 .dynamic-table-container {
    width: 100%; /* Set the desired width for the table container */
   // overflow-x: auto; /* Enable horizontal scrolling */
  }
.dynamic-table {
  border-radius: 10px 10px 0px 0px;
  overflow: hidden; /* To ensure the border-radius is applied correctly */
  width: 100%; /* Set the table width to 100% */
  border-collapse: collapse; /* Collapse table borders */
  /* Add any other styling as needed */
}
.dynamic-table thead > tr > th {
  background-color: #668FCD !important;
  color: white;
  padding: 15px !important;
  font-family: 'Outfit-Regular';
  font-size: 16px !important;
  font-weight: 400;
}
.dynamic-table tbody > tr  > td{
  padding: 15px !important;
  font-family: 'Outfit-Regular';
  font-size: 16px !important;
  font-weight: 400;
  width: 100px;
  // white-space: nowrap; /* Ensure text doesn't wrap to the next line */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Display an ellipsis (...) when text overflows */
  /* Add any other styling as needed */
}
.CustomPagination{
  border: 0px !important;
  color: #0044AB !important;
}
.CustomPagination:hover{
color: white !important;
}

.page-tabs {
  display: flex;
}

.page-tab {
  cursor: pointer;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 4px;
}

.page-tab.active {
  background-color: #fff;
  color: #001A32;
  border: 1px solid #CCDAEE;
}
.thinner-icon {
  font-weight:lighter;
}

.custom-search {
  position: relative;
  width: 100%;

}
.custom-search-input {
  width: 100%;
  border: 1px solid #F2F5F8;
  border-radius: 30px;
  padding: 35px 12px 12px 12px !important;
  box-sizing: border-box;
  outline: none;
}
.custom-search-input:hover {
  border: 1px solid #0044AB;
}
.custom-search-input::-webkit-input-placeholder {
  color: #668CB0;
  padding-left: 10px;
}

.custom-search-input:-moz-placeholder {
  color: #668CB0;
  padding-left: 10px;
}
.custom-search-input[type=text] {
  padding: 12px 20px !important;
}
.custom-search-botton {
  position: absolute;
  right: 3px; 
  top: 3px;
  bottom: 3px;
  border: 0;
  background: #0044AB;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 20px;
  border-radius: 100px;
  z-index: 2;
}