.hero-section{
    min-height: 380px;
    .illu{
  
    }

    .card-avis{
    padding: 16px;
    border-radius: 16px;
    border: 1px solid rgba(247, 249, 252, 1);
    gap: 8px;
    box-shadow: 0px -4px 19px 0px rgba(86, 86, 86, 0.06);
    background-color: #fff;
    width: 68%;
    }
    .pt-color{
        color:rgba(255, 31, 18, 1) !important;
    }
}
.bg-hero{
    background: linear-gradient(360deg, #F7F9FC -9.36%, rgba(255, 255, 255, 0) 107.4%);;
    background-image: url('../../assets/imgs/heroSection.svg');
    background-repeat: no-repeat;
    background-position: 100% 66%;
    background-size: 55%;
}
.brand-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .brand-row .col {
    flex: 0 0 calc(100% / 9); /* Set width to 1/9 of the container */
    margin: 5px; /* Adjust spacing between brands */
  }
  .section-banner{
    background-color: rgba(0, 51, 99, 1);
    align-items: center; 
  }
  .item_banner{
    align-items: center; 
    color: rgba(255, 255, 255, 1);
    margin-right: 40px;
    padding: 16.5px;
  }
 .text_banner{
  font-weight: 500;
 // font-family: Outfit;
  font-size: 18px;
 }
  .icon_banner{
    margin-right: 16px;
  }
  .brand-row img {
    max-width: 100%;
    height: auto;
  }
  @media screen and (max-width:426px){
    .section-banner{
      flex-wrap: wrap !important;
    }
  
  }
  @media screen and (max-width:426px){
    .mt-sm-12{
      margin-top:12px !important;
    }
    .hero-section .card-avis{
      width:100%;
    }
    
  }
  