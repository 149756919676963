.select-filtre {
    padding: 8px 12px !important;
    border-radius: 24px !important;
    border: 2px solid rgba(0, 68, 171, 1) !important;
    background-color: transparent !important;                   

}
.select-options{
    border-radius: 24px !important;
    box-shadow: 0px -4px 19px 0px rgba(86, 86, 86, 0.06) !important;
    border: 0.5px solid rgba(242, 245, 248, 1) !important;
    padding: 10px !important;
    z-index: 9999 !important; // Adjust z-index as needed
    text-align:left;
}
.body-s{
    color:rgba(0, 68, 171, 1) !important;
}
.dropdown-toggle::after {
    display: none !important;
  }
.value-container {
    display: flex;
    align-items: center;
  }
  .closed{
    transform: rotate(180deg);
  }
.selectedCount{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50% !important;
    width: 25px;
    height: 25px;
    gap: 10px;
    background-color: rgba(0, 68, 171, 1);
    color: #fff;
    text-align: center;
    //styleName: Légende;
    font-family: "Outfit-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 8px;

}
.moreFiltres{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px !important;
  border-radius: 50% !important;
  gap: 10px;
  background-color: rgba(0, 68, 171, 1);
  color: #fff;
  text-align: center;
  //styleName: Légende;
  font-family: "Outfit-Regular";
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;

}
.all-counted-filter{
  display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50% !important;
    width: 25px;
    height: 25px;
    gap: 10px;
    color: rgba(0, 68, 171, 1);
    background-color: #fff;
    text-align: center;
    //styleName: Légende;
    font-family: "Outfit-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 8px;
} 
.all-filter{
    padding: 8px 12px !important;
    border-radius: 24px !important;
    background: rgba(0, 68, 171, 1);
    border: 2px solid rgba(0, 68, 171, 1) !important;

    border:none !important;
    font-family:'Outfit-Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #fff;

}
.selectedFilter{

  padding: 10px 16px;
  border-radius: 24px;
  border: 1px solid rgba(204, 217, 229, 1);
  background-color: rgba(242, 245, 248, 1);
  //styleName: Body/S;
  font-family: "Outfit-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color:rgba(0, 26, 50, 1);

  

}