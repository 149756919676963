.modal-marion{
  --bs-modal-width: 629px !important;
}
.modal-content{
    padding: 32px !important;
    border-radius: 24px !important;
    gap: 8px;
    box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.05) !important;
   
}
.modal-header{
    border-bottom: none !important;
}
.rec-adr{
  //styleName: Body/S;
font-family: "Outfit-Regular";
font-size: 16px !important;
font-weight: 400 !important;
line-height: 20px !important;
letter-spacing: 0em;
text-align: left;

}
.error-adr{

padding: 16px;
border-radius: 8px;
border: 2px solid rgba(255, 31, 18, 1);
gap: 8px;
background-color: rgba(255, 244, 243, 1);
} 
.btn-close{
    background-image: url('../imgs/fi_x.svg') !important;
    background-repeat: no-repeat !important;
    opacity: 1 !important;
    background-size: 100% !important;
 }
 .all-filter-modal .modal-dialog {
    max-width: 80%;
 }
 /* The container-check */
.container-check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
  }
  .label-check{
    font-family: 'Outfit-Regular';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(0, 38, 74, 1)
  }
  
  /* Hide the browser's default checkbox */
  .container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 2px solid rgba(0, 68, 171, 1);
    border-radius: 3px;
  }
  
  /* On mouse-over, add a grey background color */
  .container-check:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container-check input:checked ~ .checkmark {
    background-color: rgba(0, 68, 171, 1);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container-check input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container-check .checkmark:after {
    left: 4px;
    top: 0px;
    width: 7px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }