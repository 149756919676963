@import './colors.scss';
.StripeElement button , .cta-primary-m{ 
    background-color:$primary-color !important;
    border:2px solid $primary-color !important;
    padding: 18px 24px !important;
    border-radius: 32px!important;
    gap: 8px!important;
    color:#fff!important;
    font-family: "Outfit-Medium"!important;
    font-size: 18px!important;
    font-weight: 500!important;
    line-height: 23px!important;
    letter-spacing: 0em!important;
    text-align: center!important;
}
.StripeElement button, .cta-primary-m:disabled, .disabled-btn{ 
    background-color:$disabled !important;
    border:2px solid $disabled !important;
    cursor: no-drop;

   
}
.cta-primary-m:disabled:hover{ 
    background-color:$disabled !important;
    border:2px solid $disabled !important;
    cursor: no-drop;
   
}
.StripeElement button, .cta-primary-m:hover{ 
    background-color:$primary-color-hover !important;
    border:2px solid $primary-color-hover !important;
    color:#fff!important;

}
.StripeElement button, .cta-primary-m:focus{ 
    background-color:$primary-color-press !important;
    border:2px solid $primary-color-press !important;
    color:#fff!important;

}
.cta-secondary-m{ 
    border:2px solid $primary-color !important;
    background-color: transparent !important;
    padding: 18px 24px !important;
    border-radius: 32px !important; 
    gap: 8px !important;
    color:$primary-color !important;
    font-family: "Outfit-Medium" !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 23px !important;
    letter-spacing: 0em;
    text-align: center;
}
.cta-secondary-m:hover{ 
    border:2px solid $primary-color-hover !important;
    color:$primary-color-hover !important;
}
.cta-secondary-m:focus{ 
    border:2px solid $primary-color-press !important;
    color:$primary-color-press !important;
}
.cta-primary-s{ 
    background-color:$primary-color !important;
    border:2px solid $primary-color !important;
    padding: 14px !important;
    border-radius: 32px!important;
    gap: 8px!important;
    color:#fff!important;
    font-family: "Outfit-Medium"!important;
    font-size: 18px!important;
    font-weight: 500!important;
    line-height: 23px!important;
    letter-spacing: 0em!important;
    text-align: center!important;
}
.cta-primary-s:hover{ 
    background-color:$primary-color-hover !important;
    border:2px solid $primary-color-hover !important;
    color:#fff!important;

}
.cta-primary-s:focus{ 
    background-color:$primary-color-press !important;
    border:2px solid $primary-color-press !important;
    color:#fff!important;

}
.cta-secondary-s{ 
    border:2px solid $primary-color !important;
    background-color: transparent !important;
    padding: 14px !important;
    border-radius: 32px !important;
    gap: 8px !important;
    color:$primary-color!important;
    font-family: "Outfit-Medium" !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 23px !important;
    letter-spacing: 0em;
    text-align: center !important;
}
.cta-secondary-s:hover{ 
    border:2px solid $primary-color-hover !important;
    color:$primary-color-hover !important;
    
}
.cta-secondary-s:focus{ 
    border:2px solid $primary-color-press !important;
    color:$primary-color-press !important;
}

// input styling
.danger-input{
    border: 2px solid rgba(255, 31, 18, 1) !important;
}
.text-danger-custom{
    //styleName: Légende;
font-family: "Outfit-Regular";
font-size: 14px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color :rgba(255, 31, 18, 1)

}