body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cta-founisseur{
  background-color: rgb(0, 68, 171) !important;
  border: 2px solid rgb(0, 68, 171) !important;
  padding: 8px 24px !important;
  border-radius: 32px !important;
  gap: 8px !important;
  color: #fff !important;
  font-family: "Outfit-Medium" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 23px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}
.search-with-filtre {
  color:rgb(0, 68, 171) !important;
  background-color: rgb(247, 249, 252)!important;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}
/* search bar */
.search-bar {
  border-radius: 50px!important;
  background-color: rgb(247, 249, 252)!important;
  justify-content: flex-end;
  gap: 8px; /* Espace entre l'input et le bouton */
}
.form-control:focus {
  box-shadow: none !important;
}
@media screen and (max-width: 1092px) {
 .positionFo-cta{
  padding-top: 20px!important;
} 
}

@media screen and (min-width: 319px) and (max-width: 425px) {
  .col-auto-resp{
    width:-webkit-fill-available!important;
    text-align: left!important;
  }
}
