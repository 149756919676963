$primary-color: rgba(0, 68, 171, 1);
$primary-color-hover: rgba(0, 54, 137, 1);
$primary-color-press: rgba(0, 41, 103, 1);
$bleu400:#336696;
$bleu500:rgba(0, 64, 124, 1);
$bleu600:rgba(0, 51, 99, 1);
$bleu700:rgba(0, 38, 74, 1);
$bleu800:rgba(0, 26, 50, 1);
$disabled:rgba(102, 102, 102, 1);
$interaction-dark-bg:#fff;
$hover-dark-bg:#D5D4D5;
$press-dark-bg:#969596;
$light-bg:#F5F5F7;
$dark-bg:#2D2A2C;
$text-color:#121010;
$subText-color:#7D7779;
$success:#7BBE6A;
$error:#FF8A00;
$input-bg:#FAFAFA;
